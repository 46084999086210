import React from 'react';

const OpenCalendar = ( title, description, color, label ) => {
    const timeOffsetInMS = new Date().getTimezoneOffset() * 60000;
    const startDate = new Date();
    startDate.setTime(startDate.getTime() - timeOffsetInMS);
    const endDate = new Date(new Date(startDate).setTime(startDate.getTime() + 1 * 60 * 60 * 1000));
    const startString = startDate
        .toISOString()
        .replace(/[-:]/g, '')
        .split('.')[0];
    const endString = endDate
        .toISOString()
        .replace(/[-:]/g, '')
        .split('.')[0];

    window.open(`https://calendar.google.com/calendar/r/eventedit?sf=true&output=xml&text=${title}&dates=${startString}/${endString}&ctz=America/New_York&details=${description}`, '_blank');

    return null;
};

export default OpenCalendar;
