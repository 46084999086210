import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import './archived-plan-item.scss';
import { planService } from '../services';
import { ArhivedPageContext } from './index';

const ArchivedPlanItem = ({ plan }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedName, setEditedName] = useState(plan.name);
    const { setLoading } = useContext(ArhivedPageContext);
    const downloadCsvUrl = planService.getDownloadCsvUrl(plan.id);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        setLoading(true);
        planService
            .renamePlan(plan.id, editedName)
            .then(() => {
                plan.name = editedName;
            })
            .finally(() => {
                setIsEditing(false);
                setLoading(false);
            });
    };

    const handleCancelClick = () => {
        setEditedName(plan.name);
        setIsEditing(false);
    };

    const handleNameChange = e => {
        setEditedName(e.target.value);
    };

    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            handleSaveClick();
        } else if (e.key === 'Escape') {
            handleCancelClick();
        }
    };

    return (
        <div className="archived-plan-item">
            <div className="name-and-link">
                {isEditing ? (
                    <input
                        type="text"
                        value={editedName}
                        onChange={handleNameChange}
                        onKeyDown={handleKeyDown}
                        className="input-plan-name"
                    />
                ) : (
                    <h2>{plan.name}</h2>
                )}
                {isEditing ? (
                    <>
                        <FontAwesomeIcon
                            className="action"
                            icon={faTimes}
                            size="1x"
                            onClick={handleCancelClick}
                        />
                        <FontAwesomeIcon
                            className="action"
                            icon={faCheck}
                            size="1x"
                            onClick={handleSaveClick}
                        />
                    </>
                ) : (
                    <>
                        <FontAwesomeIcon
                            className="action"
                            icon={faEdit}
                            size="1x"
                            onClick={handleEditClick}
                        />
                    </>
                )}
            </div>
            <div className="download-actions">
                <a href={downloadCsvUrl} download>
                    CSV
                </a>
                {/* <a href={plan.pdfUrl} download>
                PDF
            </a> */}
            </div>
        </div>
    );
};

export default ArchivedPlanItem;
