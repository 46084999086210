import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { planService } from '../services';
import { useHistory } from 'react-router-dom';

const PrimaryNav = ({ location: { pathname, search } = {}, staffId }) => {
    const [pageTitle, setPageTitle] = useState('');
    const history = useHistory();

    useEffect(() => {
        if (pathname == '/archived') {
            setPageTitle('Saved Plans');
        } else {
            setPageTitle('Facilitation Plan: ' + new Date().toLocaleDateString());
        }
    }, [pathname]);

    const onSavePlan = () => {
        planService.savePlan(staffId).then(() => {
            history.push(`/archived${search}`);
        });
    };

    return (
        <nav>
            <div className="nav-wrapper">
                <div className="primary-nav">
                    <div style={{ fontWeight: 'bold', fontSize: 20 }}>{pageTitle}</div>
                    <button className="button-white" onClick={onSavePlan}>
                        Save This Plan
                    </button>
                </div>
            </div>
            <div className="nav-wrapper nav-wrapper--secondary">
                <div className="secondary-nav">
                    <div>
                        <Link
                            to={`/${search}`}
                            className={classnames({
                                'is-active': pathname && pathname.indexOf('group') < 0,
                            })}
                        >
                            Individual Planning
                        </Link>

                        <Link
                            to={`/group-management${search}`}
                            style={{ display: 'none' }}
                            className={classnames({
                                'is-active': pathname && pathname.indexOf('group') >= 0,
                            })}
                        >
                            Group Planning
                        </Link>
                    </div>
                    <div>
                        <Link to={`/archived${search}`}>View Saved Plans</Link>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default PrimaryNav;
