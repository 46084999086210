import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, } from '@fortawesome/free-solid-svg-icons';
import { useGroupContext } from '../../../components/GroupContext';

const GroupItem = ({ group }) => {
    const { onUpdateGroup } = useGroupContext();
    const onChangeDate = evt => {
        const {
            target: { value },
        } = evt;

        onUpdateGroup({ ...group, date: value });
    };
    return (
        <tr>
            <td style={{ width: '25%' }}>{group.name}</td>
            <td>
                {group.date ? (
                    new Date(group.date).toLocaleDateString()
                ) : (
                    <input
                        id="group-date"
                        value={group.date || ''}
                        onChange={onChangeDate}
                        className="input"
                        type="date"
                    />
                )}
            </td>
            <td>
                {group.students &&
                    group.students.map((s, index) => (
                        <span key={index}>
                            {s.name}
                            {index < group.students.length - 1 && ', '}
                        </span>
                    ))}
            </td>
            <td>
                {group.task && (
                    <div>
                        <div>{group.task.name}</div>
                        <ul className="group-item__competencies">
                            {group.task.competencies &&
                                group.task.competencies.map((c, index) => (
                                    <li key={index}>
                                        {c}
                                        {index < group.task.competencies.length - 1 && ', '}
                                    </li>
                                ))}
                        </ul>
                    </div>
                )}
            </td>
            <td>{group.notes}</td>
            <td>
                <div className="group-item__actions">
                    <button>
                        <FontAwesomeIcon icon={faEdit} color="#0055aa" size="1x" />
                    </button>
                    <button>
                        <FontAwesomeIcon icon={faTrash} color="#0055aa" size="1x" />
                    </button>
                </div>
            </td>
        </tr>
    );
};

export default GroupItem;
