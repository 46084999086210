import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const GoogleCalendarLink = ({ title, description, color, label, showIcon = true, onClick }) => {
    const timeOffsetInMS = new Date().getTimezoneOffset() * 60000;
    const startDate = new Date();
    startDate.setTime(startDate.getTime() - timeOffsetInMS);
    const endDate = new Date(new Date(startDate).setTime(startDate.getTime() + 1 * 60 * 60 * 1000));
    const startString = startDate
        .toISOString()
        .replace(/[-:]/g, '')
        .split('.')[0];
    const endString = endDate
        .toISOString()
        .replace(/[-:]/g, '')
        .split('.')[0];

    return (
        <div>
            <a
                target="_blank"
                rel="noopener noreferrer"
                className={label ? 'button-link' : ''}
                onClick={onClick}
                href={`https://calendar.google.com/calendar/r/eventedit?sf=true&output=xml&text=${title}&dates=${startString}/${endString}&ctz=America/New_York&details=${description}`}
            >
                <div>
                    {label ? (
                        label
                    ) : (
                        <FontAwesomeIcon icon={faCalendarAlt} size="1x" color={color || '#999'} />
                    )}
                </div>
            </a>
        </div>
    );
};

export default GoogleCalendarLink;
