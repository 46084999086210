import React from 'react';
import TaskList from '../TaskList';
import { useDataContext } from '../../../components/DataContext';
import './student-detail.scss';
import { MoonLoader } from 'react-spinners';

const StudentDetail = ({ show, student, selectedCourse, plannedTask, tasksLoading }) => {
    const { trafficLight } = student;
    const { setNotesForStudent, openStudentConferenceModal } = useDataContext();

    const onChangeStudentNotes = evt => {
        const {
            target: { value },
        } = evt;

        setNotesForStudent(student.id, value);
    };

    const onCreateConference = () => {
        openStudentConferenceModal(student);
    };

    return (
        <div
            className={
                show ? 'student-item-detail student-item-detail--show' : 'student-item-detail'
            }
        >
            <div className="student-item-detail__inner">
                {selectedCourse ? (
                    <>
                        <div className="student-item-detail__left">
                            <div className="item">
                                <h4>{student.name}</h4>
                                <div className="value">
                                    <button className="button-link" onClick={onCreateConference}>
                                        Create Conference
                                    </button>
                                </div>
                            </div>

                            <div>
                                <div>
                                    Average Tasks: {student.submitted ?? 0} out of{' '}
                                    {student.taskGoals ?? 0}
                                </div>
                                <div>
                                    Attendance: {student.lastTenDayAttendance ?? 0}% out of last 10
                                    days
                                </div>
                            </div>
                            <div className="item">
                                <h4>Subgroup: </h4>
                                <div className="value">{student.subGroup?.join(', ')}</div>
                            </div>
                            <div className="item">
                                <h4>Accommodations: </h4>
                                <div className="value">{student.accommodations?.join(', ')}</div>
                            </div>
                            <div className="item">
                                <h4>Notes: </h4>
                                <div className="value">{student.notes}</div>
                            </div>
                        </div>
                        <div className="student-item-detail__right">
                            {tasksLoading ? (
                                <div className="task-loading-container">
                                    <MoonLoader
                                        loading={tasksLoading}
                                        size={50}
                                        color={'#0055aa'}
                                    />
                                </div>
                            ) : (
                                <TaskList student={student} course={selectedCourse} />
                            )}
                        </div>
                    </>
                ) : (
                    <div>This student does not have any programmed courses.</div>
                )}
            </div>
        </div>
    );
};

StudentDetail.propTypes = {};
StudentDetail.defaultProps = {};

export default StudentDetail;
