const getStudentProfileUrl = () => {
    if (process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'staging')
        return BASE_PATH + '/bronxarena/common/studentlanding';
    else if (process.env.REACT_APP_ENV === 'production')
        return 'https://tracker.bronxarena.org/common/studentlanding';
};

const getTaskUrl = () => {
    if (process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'staging')
        return BASE_PATH + '/BronxArena/CommonTask';
    else if (process.env.REACT_APP_ENV === 'production')
        return 'https://tracker.bronxarena.org/CommonTask';
};

const getPersonalizeUrl = () => {
    if (process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'staging')
        return BASE_PATH + '/bronxarena/common/personalize';
    else if (process.env.REACT_APP_ENV === 'production')
        return 'https://tracker.bronxarena.org/common/personalize';
};

const BASE_PATH = process.env.REACT_APP_API_URL;
const BASE_URL = BASE_PATH + '/facilitationplanservice/api/';
const FETCH_PLAN_ENDPOINT = 'facilitationplans';
const FETCH_TASKS_ENDPOINT = 'gettasks';
const UPDATE_TASK_NOTES_ENDPOINT = 'updatetasknotes';
const FETCH_PLAN_HISTORY_ENDPOINT = 'facilitationPlanHistory';
const RENAME_FACILITATION_PLAN_ENDPOINT = 'renameFacilitationPlan';
const DOWNLOAD_CSV_ENDPOINT = 'downloadcsv';
const SAVE_PLAN_ENDPOINT = 'savefacilitationplan';
const SAVE_CONFERENCE_ENDPOINT = 'saveconference';
const EDIT_CONFERENCE_ENDPOINT = 'saveconference';
const DELETE_CONFERENCE_ENDPOINT = 'deleteconference';
const STUDENT_PROFILE_URL = getStudentProfileUrl();
const TASK_URL = getTaskUrl();
const PERSONALIZE_URL = getPersonalizeUrl();

export default {
    BASE_PATH,
    BASE_URL,
    FETCH_PLAN_ENDPOINT,
    FETCH_TASKS_ENDPOINT,
    UPDATE_TASK_NOTES_ENDPOINT,
    FETCH_PLAN_HISTORY_ENDPOINT,
    RENAME_FACILITATION_PLAN_ENDPOINT,
    DOWNLOAD_CSV_ENDPOINT,
    SAVE_CONFERENCE_ENDPOINT,
    EDIT_CONFERENCE_ENDPOINT,
    DELETE_CONFERENCE_ENDPOINT,
    STUDENT_PROFILE_URL,
    TASK_URL,
    PERSONALIZE_URL,
    SAVE_PLAN_ENDPOINT,
};
