import React from 'react';
import StudentListItem from './StudentListItem';
import './student-list.scss';

const StudentList = ({ students }) => {
    if (!students || students.length === 0) {
        return <h2 style={{ textAlign: 'center' }}>Sorry there are no results for this filter</h2>;
    }
    return (
        <>
            <div className="student-list__header">
                <div>Student</div>
                <div>Courses</div>
                <div>Plan</div>
            </div>
            <div>
                {students.map((student, index) => (
                    <StudentListItem key={index} student={student} />
                ))}
            </div>
        </>
    );
};

StudentList.propTypes = {};
StudentList.defaultProps = {};

export default StudentList;
