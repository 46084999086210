import React from 'react';
import classnames from 'classnames';
const MatchingStudentListItem = ({ student, task, onChange, isSelected }) => {
    const onSelectStudent = () => {
        onChange(student, !isSelected);
    };

    return (
        <li>
            <button
                className={classnames('matching-students-list__item', {
                    'matching-students-list__item--selected': isSelected,
                })}
                onClick={onSelectStudent}
            >
                <div className="matching-students-list__student">{student.name}</div>
                <div className="matching-students-list__task">{task.name}</div>
                <ul>
                    {task.competencies &&
                        task.competencies.map((c, index) => <li key={index}>{c}</li>)}
                </ul>
            </button>
        </li>
    );
};

const MatchingStudentList = ({ matches, selected, onChange }) => {
    return (
        <React.Fragment>
            {!matches || matches.length <= 0 ? (
                <div>no matching students</div>
            ) : (
                <ul className="matching-students-list">
                    {matches.map(({ student, task }, index) => {
                        return (
                            <MatchingStudentListItem
                                key={index}
                                student={student}
                                task={task}
                                onChange={onChange}
                                isSelected={
                                    selected &&
                                    selected.find(
                                        selectedStudent =>
                                            selectedStudent && selectedStudent.id === student.id,
                                    )
                                }
                            />
                        );
                    })}
                </ul>
            )}
        </React.Fragment>
    );
};

export default MatchingStudentList;
