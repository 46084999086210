import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import Downshift from 'downshift';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import './typeahead.scss';

const TypeAhead = ({
    selectedValue,
    items,
    prop,
    onChange,
    placeholder,
    alwaysOpen,
    renderItem,
}) => {
    const [value, setValue] = useState('');

    useEffect(() => {
        if (!selectedValue) {
            setValue('');
        } else {
            setValue(selectedValue[prop] || selectedValue.toString());
        }
    }, [selectedValue, prop]);

    const handeStateChange = changes => {
        if (changes.hasOwnProperty('inputValue')) {
            setValue(changes.inputValue);
        }
    };

    const itemToString = item => {
        if (item) {
            if (prop) {
                return item[prop];
            }
            return item.toString();
        }
        return '';
    };

    const renderItemDefault = item => {
        return prop ? item[prop] : item.toString();
    };

    return (
        <Downshift
            inputValue={value}
            selectedItem={selectedValue}
            onChange={onChange}
            onStateChange={handeStateChange}
            itemToString={itemToString}
        >
            {({
                getInputProps,
                getItemProps,
                getLabelProps,
                getMenuProps,
                isOpen,
                inputValue,
                highlightedIndex,
                selectedItem,
                clearSelection,
            }) => {
                const showPanel = isOpen || (alwaysOpen && !selectedItem && items.length > 0);
                return (
                    <div className="typeahead">
                        <input
                            {...getInputProps()}
                            placeholder={placeholder}
                            className={classnames('input', {
                                'input-panel-attached': showPanel,
                            })}
                        />
                        <button
                            type="button"
                            onClick={clearSelection}
                            className={
                                selectedItem
                                    ? 'typeahead-clear typeahead-clear--show'
                                    : 'typeahead-clear'
                            }
                        >
                            <FontAwesomeIcon icon={faTimes} size="1x" />
                        </button>
                        <ul
                            {...getMenuProps()}
                            className={
                                showPanel ? 'typeahead-list typeahead-list--open' : 'typeahead-list'
                            }
                        >
                            {showPanel
                                ? items
                                      .filter(item => {
                                          if (!inputValue) return true;
                                          const normalizedInputValue = inputValue.toLowerCase();
                                          if (prop && item[prop]) {
                                              return item[prop]
                                                  .toLowerCase()
                                                  .includes(normalizedInputValue);
                                          }
                                          if (typeof item === 'string') {
                                              return item
                                                  .toLowerCase()
                                                  .includes(normalizedInputValue);
                                          }
                                          return false;
                                      })
                                      .map((item, index) => (
                                          <li
                                              {...getItemProps({
                                                  key: index,
                                                  index,
                                                  item,
                                                  style: {
                                                      backgroundColor:
                                                          highlightedIndex === index
                                                              ? '#f1f5d3'
                                                              : 'white',
                                                      fontWeight:
                                                          selectedItem === item ? 'bold' : 'normal',
                                                  },
                                              })}
                                          >
                                              {renderItem
                                                  ? renderItem(item)
                                                  : renderItemDefault(item)}
                                          </li>
                                      ))
                                : null}
                        </ul>
                    </div>
                );
            }}
        </Downshift>
    );
};

export default TypeAhead;
