import axios from 'axios';
import config from '../config';

export const fetchTasks = (staffId, studentid, courseid) => {
    return axios.get(
        `${config.BASE_URL}${config.FETCH_TASKS_ENDPOINT}?studentid=${studentid}&courseid=${courseid}`,
    );
};

export const updateNotes = (staffid, taskid, studentid, courseid, notes, isactive) => {
    return axios.get(
        `${config.BASE_URL}${config.UPDATE_TASK_NOTES_ENDPOINT}?taskid=${taskid}&studentid=${studentid}&courseid=${courseid}&notes=${notes}&isactive=${isactive}`,
    );
};

export const getTaskUrl = (studentid, staffid, courseid, taskid) => {
    return `${config.TASK_URL}/${studentid}/${staffid}#!/course/${courseid}/task/${taskid}`;
};
