import React, { createContext, useContext, useState } from 'react';
import { oneOfType, arrayOf, node, object } from 'prop-types';
import { useHistory } from 'react-router-dom';

const getMatchingStudents = ({
    task,
    students,
    matchWithinCourse,
    matchByCompetency,
    selectedStudent,
}) => {
    const matchingStudents =
        task &&
        students.reduce((accumulator, current) => {
            let matchingTask = null;
            if (current.courses) {
                current.courses.forEach(course => {
                    const isMyCourse = course.teacher && course.teacher.indexOf('test') >= 0;
                    if (!matchingTask && isMyCourse) {
                        // make sure task is same teacher
                        matchingTask = course.tasks
                            .filter(t => t.status !== 'Complete')
                            .find((t, index) => {
                                if (matchWithinCourse && course.name !== t.course) {
                                    return false;
                                }
                                const matchesName = t.name === task.name;
                                const hasMatchingCompetency =
                                    t.competencies &&
                                    t.competencies.find(
                                        competency =>
                                            task.competencies &&
                                            task.competencies.indexOf(competency) >= 0,
                                    );

                                return matchesName || (matchByCompetency && hasMatchingCompetency);
                            });
                    }
                });
            }

            if (!matchingTask && selectedStudent && current.id === selectedStudent.id) {
                accumulator.push({
                    student: current,
                    task,
                });
            }
            if (matchingTask) {
                accumulator.push({
                    student: current,
                    task: matchingTask,
                });
            }
            return accumulator;
        }, []);

    return matchingStudents;
};

const Context = createContext();

export const GroupProvider = ({ data, children }) => {
    const [groups, setGroups] = useState([]);
    const [students] = useState(data);
    const [notes, setNotes] = useState('');
    const [groupName, setGroupName] = useState('');
    const [selectedStudent, setSelectedStudent] = useState('');
    const [selectedTask, setSelectedTask] = useState('');
    const [groupDate, setGroupDate] = useState('');
    const [studentsInGroup, setStudentsInGroup] = useState([]);
    const [availableTasks, setAvailableTasks] = useState([]);
    const [availableStudents, setAvailableStudents] = useState([]);
    const [matchWithinCourse, setMatchWithinCourse] = useState(true);
    const [matchByCompetency, setMatchByCompetency] = useState(true);

    const history = useHistory();

    const onSelectStudent = student => {
        setSelectedStudent(student);
        const nextAvailableTasks =
            student && student.courses
                ? student.courses.reduce((accumulator, current) => {
                      return accumulator.concat(
                          current.tasks.filter(task => task.facilitationFlag === 'group'),
                      );
                  }, [])
                : [];
        setStudentsInGroup([student]);
        setAvailableTasks(nextAvailableTasks);
    };

    const onSelectTask = (task, student) => {
        if (!task) {
            setSelectedTask(null);
            setAvailableStudents([]);
            return;
        }
        setSelectedTask(task);
        setAvailableStudents(
            getMatchingStudents({
                task,
                students,
                matchWithinCourse,
                matchByCompetency,
                selectedStudent: selectedStudent || student,
            }),
        );
    };

    const onAddGroup = () => {
        if (!studentsInGroup || studentsInGroup.length < 2) {
            return alert('You need at least 2 students to make a group');
        }

        setGroups(
            groups.concat([
                {
                    id: Math.random().toString(),
                    name: groupName,
                    students: studentsInGroup,
                    date: groupDate,
                    task: selectedTask,
                    notes,
                },
            ]),
        );
        setSelectedStudent('');
        setGroupDate('');
        setSelectedTask('');
        setStudentsInGroup([]);
        setNotes('');
        setGroupName('');
        history.push('/group-management');
    };

    const onUpdateGroup = (group, index) => {
        setGroups(
            groups.map(g => {
                if (g.id === group.id) {
                    return group;
                }
                return g;
            }),
        );
    };

    return (
        <Context.Provider
            value={{
                students,
                notes,
                setNotes,
                groupName,
                setGroupName,
                selectedStudent,
                setSelectedStudent: onSelectStudent,
                selectedTask,
                setSelectedTask: onSelectTask,
                groupDate,
                setGroupDate,
                studentsInGroup,
                setStudentsInGroup,
                availableTasks,
                availableStudents,
                groups,
                onAddGroup,
                onUpdateGroup,
                matchWithinCourse,
                setMatchWithinCourse: value => {
                    setMatchWithinCourse(value);
                    setAvailableStudents(
                        getMatchingStudents({
                            task: selectedTask,
                            students,
                            selectedStudent,
                            matchWithinCourse: value,
                            matchByCompetency,
                        }),
                    );
                },
                matchByCompetency,
                setMatchByCompetency: value => {
                    setMatchByCompetency(value);
                    setAvailableStudents(
                        getMatchingStudents({
                            task: selectedTask,
                            students,
                            matchWithinCourse,
                            selectedStudent,
                            matchByCompetency: value,
                        }),
                    );
                },
            }}
        >
            {children}
        </Context.Provider>
    );
};

Context.propTypes = {
    children: oneOfType([arrayOf(node), node]),
    data: arrayOf(object),
};

export const useGroupContext = () => {
    const context = useContext(Context);
    if (!context) {
        throw new Error(
            'This component can only be rendered as a child of the DataProvider component',
        );
    }
    return context;
};
