import React from 'react';
import StudentFilters from './components/StudentFilters';
import StudentList from './components/StudentList';
import { useDataContext } from '../components/DataContext';

const IndividualPlan = () => {
    const { students, filteredStudents } = useDataContext();

    return (
        <div>
            <StudentFilters students={students} />
            <StudentList students={filteredStudents} />
        </div>
    );
};

export default IndividualPlan;
