import React, { useEffect, useRef } from 'react';
import Task from './Task';

import './task-list.scss';
import { courseServise } from '../../../services';
import { useDataContext } from '../../../components/DataContext';

const TaskList = ({ course = {}, student }) => {
    const listRef = useRef();
    const taskRefs = {};
    const { tasks = [], plannedTask } = course;
    const { staffId } = useDataContext();
    const courseNameColor = course.isFocusCredit ? '#ff6600' : '#555555';
    const taskStatusPriority = {
        NeedHelp: 1,
        Submitted: 2,
        Revise: 3,
        Open: 4,
    };

    useEffect(() => {
        scrollToHighPriorityTask();
    }, []);

    useEffect(() => {
        if (listRef.current && listRef.current.scrollTop !== getHighPriorityTaskPosition())
            scrollToHighPriorityTask();
    }, [taskRefs]);

    const scrollToHighPriorityTask = () => {
        const highPriorityTask = getHighPriorityTask(tasks);
        if (highPriorityTask && listRef.current) {
            const el = taskRefs[highPriorityTask.id];
            if (el) {
                listRef.current.scrollTop = el.offsetTop;
            }
        }
    };

    const getHighPriorityTaskPosition = () => {
        const highPriorityTask = getHighPriorityTask(tasks);
        return (
            highPriorityTask &&
            taskRefs[highPriorityTask.id] &&
            taskRefs[highPriorityTask.id].offsetTop
        );
    };

    const getHighPriorityTask = tasks => {
        const mappedTasks = tasks.map(task => ({
            ...task,
            priority: getTaskPriority(task),
        }));

        const sortedTasks = mappedTasks.sort((a, b) => a.priority - b.priority);
        return sortedTasks[0];
    };

    const getTaskPriority = task => {
        return taskStatusPriority[task.status] ?? Number.MAX_VALUE;
    };

    const personalizeUrl = courseServise.getPersonalizeUrl(staffId, student.id, course.id);

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div
                    style={{
                        display: 'flex',
                        marginRight: 20,
                        alignItems: 'center',
                        marginBottom: 10,
                    }}
                >
                    <strong style={{ color: courseNameColor }}>{course.name}</strong>
                    <a
                        style={{ marginLeft: 10 }}
                        className="button-link"
                        href={personalizeUrl}
                        onClick={evt => evt.stopPropagation()}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Personalize
                    </a>
                </div>
                <div className="task-list-filters"></div>
            </div>

            <ul className="task-list" ref={listRef}>
                {tasks &&
                    tasks.map((task, index) => (
                        <li key={index} ref={ref => (taskRefs[task.id] = ref)}>
                            <Task
                                task={task}
                                course={course}
                                isPlanned={task.id === plannedTask}
                                student={student}
                            />
                        </li>
                    ))}
            </ul>
        </div>
    );
};

export default TaskList;
