import { useLocation } from 'react-router-dom';

function useStaffIdQuery() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const staffId = params.get('staffId');

    return staffId;
}

export default useStaffIdQuery;
