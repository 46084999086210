import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './modal.scss';
import { MoonLoader } from 'react-spinners';

const Modal = ({ children, onClose, loading }) => {
    const onClickClose = evt => {
        evt.stopPropagation();
        if (onClose) {
            onClose();
        }
    };
    return (
        <div className="modal-background">
            <div className="modal-wrapper">
                {loading && (
                    <div className="modal-loader">
                        <MoonLoader loading={loading} size={40} color={'#0055aa'} />
                    </div>
                )}
                <button onClick={onClickClose} className="modal-close">
                    <FontAwesomeIcon icon={faTimes} size="2x" />
                </button>
                <div className="modal-content">{children}</div>
            </div>
        </div>
    );
};

export default Modal;
