import React, { useEffect } from 'react';
import useStaffIdQuery from '../utils/hooks/useStaffIdQuery';
import { useDataContext } from './DataContext';

const StaffIdProvider = ({ children }) => {
    const { staffId, setStaffId } = useDataContext();
    const staffIdFromQuery = useStaffIdQuery();

    useEffect(() => {
        if (staffIdFromQuery && staffIdFromQuery !== staffId) {
            setStaffId(staffIdFromQuery);
        }
    }, [staffIdFromQuery, staffId]);

    return <>{children}</>;
};

export default StaffIdProvider;
