import React, { useEffect, useState, createContext } from 'react';
import ArchivedPlanItem from './archived-plan-item';
import { MoonLoader } from 'react-spinners';

import { fetchPlanHistory } from '../services/plan';
import { useDataContext } from '../components/DataContext';

export const ArhivedPageContext = createContext();

const ArhivedPage = () => {
    const [archivedPlans, setArchivedPlans] = useState([]);
    const [loading, setLoading] = useState(true);
    const { staffId } = useDataContext();

    useEffect(() => {
        setLoading(true);
        fetchPlanHistory(staffId)
            .then(response => {
                setArchivedPlans(response.data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, []);

    return (
        <ArhivedPageContext.Provider value={{ setLoading }}>
            {loading ? (
                <div className="loading-container">
                    <MoonLoader loading={loading} size={70} color={'#0055aa'} />
                </div>
            ) : (
                <>
                    <h1>List of archived facilitation plans would be displayed here.</h1>
                    <div className="archived-plan-list">
                        {archivedPlans.map(plan => (
                            <ArchivedPlanItem key={plan.id} plan={plan} />
                        ))}
                    </div>
                </>
            )}
        </ArhivedPageContext.Provider>
    );
};

export default ArhivedPage;
