import axios from 'axios';
import config from '../config';

export const saveConference = (staffId, studentId, date, notes) => {

    const saveConfParams = `staffId=${staffId}&studentId=${studentId}&date=${date}&notes=${notes}`;
    const saveConferenceQuery = `${config.BASE_URL}${config.SAVE_CONFERENCE_ENDPOINT}?${saveConfParams}`;

    return axios.get(saveConferenceQuery);
};

export const editConference = (id, staffId, studentId, date, notes) => {
    const editConfParams = `id=${id}&staffId=${staffId}&studentId=${studentId}&date=${date}&notes=${notes}`;
    const editConferenceQuery = `${config.BASE_URL}${config.SAVE_CONFERENCE_ENDPOINT}?${editConfParams}`;

    return axios.get(editConferenceQuery);
};

export const deleteConference = id => {
    return axios.get(`${config.BASE_URL}${config.DELETE_CONFERENCE_ENDPOINT}?id=${id}`);
};
