import React from 'react';
import { useGroupContext } from '../components/GroupContext';
import { Link } from 'react-router-dom';
import GroupList from './components/GroupList';

const GroupPlan = () => {
    const { groupName, setGroupName, groups } = useGroupContext();

    const onChangeGroupName = evt => {
        const {
            target: { value },
        } = evt;
        setGroupName(value);
    };

    return (
        <div>
            <GroupList groups={groups} />
            <label htmlFor="create-group">Create Group</label>
            <div className="input-group">
                <input
                    className="input"
                    id="create-group"
                    type="text"
                    value={groupName}
                    onChange={onChangeGroupName}
                    placeholder="Enter group name"
                />
                <Link to="/group-management/create" className="button-white">
                    Create
                </Link>
            </div>
        </div>
    );
};

export default GroupPlan;
