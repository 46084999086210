import React, { useEffect, useState } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Individual from './Individual';
import Group from './Group';
import Archived from './Archived';
import CreateGroup from './Group/CreateGroup';

import { DataProvider } from './components/DataContext';
import { GroupProvider } from './components/GroupContext';
import PrimaryNav from './components/PrimaryNav';
import { MoonLoader } from 'react-spinners';

import { planService } from './services';
import './app.scss';
import StaffIdProvider from './components/StaffIdProvider';
import StudentConferenceModal from './Individual/components/StudentConferenceModal';

function App() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [staffId, setStaffId] = useState(1);

    useEffect(() => {
        const source = axios.CancelToken.source();
        const { staffid = 29 } = queryString.parse(window.location.search?.toLowerCase());
        setStaffId(staffid);
        fetchData(staffid, source);

        return () => {
            source.cancel();
        };
    }, []);

    const fetchData = (staffid, cancelSource) => {
        planService
            .fetchPlanData(staffid, cancelSource)
            .then(response => {
                setData(items => [...items, ...response.data]);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    return loading ? (
        <div className="loading-container">
            <MoonLoader loading={loading} size={70} color={'#0055aa'} />
        </div>
    ) : (
        <Router>
            <Route path="*" render={props => <PrimaryNav staffId={staffId} {...props} />}></Route>
            <DataProvider data={data}>
                <GroupProvider data={data}>
                    <StaffIdProvider>
                        <div className="page-wrapper">
                            <div className="page-wrapper__inner">
                                <Route exact path={['/', '/BronxArena/FacilitationPlan/']}>
                                    <Individual />
                                </Route>
                                <Route exact path="/group-management">
                                    <Group />
                                </Route>
                                <Route exact path="/group-management/create">
                                    <CreateGroup />
                                </Route>
                                <Route exact path="/archived">
                                    <Archived />
                                </Route>
                                <StudentConferenceModal />
                            </div>
                        </div>
                    </StaffIdProvider>
                </GroupProvider>
            </DataProvider>
        </Router>
    );
}

export default App;
