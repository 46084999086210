import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCalendarDay, faMinus, faLink } from '@fortawesome/free-solid-svg-icons';
import OpenCalendar from '../../../components/OpenCalendar';
import { useDataContext } from '../../../components/DataContext';
import { useGroupContext } from '../../../components/GroupContext';
import './task.scss';
import { taskService } from '../../../services';

const Task = ({ student, course, task, isPlanned, isSelected, isStatic, hideBorder }) => {
    const { setPlannedTask, saveNotesForTask, staffId } = useDataContext();
    const { setSelectedTask, setSelectedStudent, setGroupName } = useGroupContext();

    const [notes, setNotes] = useState(task.notes);
    const [isNotesChanged, setNotesChanged] = useState(false);
    const { notes: taskNotes } = task;

    useEffect(() => {
        setNotes(taskNotes);
    }, [taskNotes]);

    const onUpdateNotes = evt => {
        const {
            target: { value },
        } = evt;
        setNotes(value);
        setNotesChanged(true);
    };

    const onAddTask = () => {
        setPlannedTask(student, course, task.id, task.notes);
        taskService.updateNotes(staffId, task.id, student.id, task.courseId, notes ?? '', true);
    };

    const onRemoveTask = () => {
        setPlannedTask(student, course, null, null);
        taskService.updateNotes(staffId, task.id, student.id, task.courseId, notes ?? '', false);
    };

    const openCalendarIcon = () => {
        OpenCalendar(task.name, task.description, '#999');
    };

    const onSaveNotes = () => {
        if (!isNotesChanged) return;
        saveNotesForTask(student, course, task.id, notes);
        taskService.updateNotes(
            staffId,
            task.id,
            student.id,
            task.courseId,
            notes,
            isPlanned ?? false,
        );
        setNotesChanged(false);
    };

    const onCreateGroup = () => {
        setSelectedStudent(student);
        setSelectedTask(task, student);
        setGroupName(task.name + ' group');
    };

    const taskLink = taskService.getTaskUrl(
        student.id,
        staffId,
        task.courseId ?? course?.id,
        task.id,
    );

    return (
        <div
            className={classnames('task', {
                'task--planned': isPlanned,
                'task--selected': isSelected && !isPlanned,
                'task--hide-border': hideBorder,
                'task--static': isStatic,
                'task--hidden': isSelected && isPlanned,
            })}
        >
            <div className="task-details-container">
                <div className="task-details">
                    <span className={`task-name task-status--${task.status}`}>{task.name}</span>
                    <div style={{ minWidth: '50px', paddingLeft: '30px' }}>
                        {isPlanned ? (
                            <FontAwesomeIcon
                                icon={faMinus}
                                style={{ float: 'left', marginRight: '5px' }}
                                color="#999"
                                size="lg"
                                onClick={onRemoveTask}
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faPlus}
                                style={{ float: 'left', marginRight: '5px' }}
                                color="#999"
                                size="lg"
                                onClick={onAddTask}
                            />
                        )}
                        <FontAwesomeIcon
                            icon={faCalendarDay}
                            style={{ float: 'left', marginRight: '5px' }}
                            color="#999"
                            size="lg"
                            onClick={openCalendarIcon}
                        />
                        <a
                            href={taskLink}
                            onClick={evt => evt.stopPropagation()}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon
                                icon={faLink}
                                style={{ float: 'left', marginRight: '5px' }}
                                color="#999"
                                size="lg"
                            />
                        </a>
                    </div>
                </div>
                <div className="task-details">
                    <div style={{ display: 'none' }} className="task-competencies">
                        {task.competencies &&
                            task.competencies.map((comp, index) => <div key={index}>{comp}</div>)}
                    </div>
                </div>
            </div>

            <div className="task-notes">
                <textarea
                    value={notes ?? ''}
                    placeholder={`Enter task notes`}
                    onChange={onUpdateNotes}
                    onBlur={onSaveNotes}
                ></textarea>
            </div>
        </div>
    );
};

export default Task;
