import React from 'react';
import GroupItem from '../GroupItem';
import './group-list.scss';

const GroupList = ({ groups }) => {
    if (!groups || groups.length === 0) {
        return null;
    }
    return (
        <table className="group-list">
            <thead>
                <tr>
                    <th>Group Name</th>
                    <th>Date</th>
                    <th>Students</th>
                    <th>Task / Competency</th>
                    <th colSpan="2" style={{ minWidth: '20%' }}>
                        Notes
                    </th>
                </tr>
            </thead>
            <tbody>
                {groups.map((group, index) => (
                    <GroupItem key={index} group={group} />
                ))}
            </tbody>
        </table>
    );
};

export default GroupList;
