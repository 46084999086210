import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCaretDown,
    faCalendarAlt,
    faSearch,
    faLink,
    faTrash,
    faEdit,
} from '@fortawesome/free-solid-svg-icons';
import StudentDetail from '../StudentDetail';
import Task from '../TaskList/Task';
import { useDataContext } from '../../../components/DataContext';
import { conferenceService, studentService, taskService } from '../../../services';
import { BeatLoader } from 'react-spinners';

const StudentItem = ({ student = {} }) => {
    const taskRef = useRef();
    const {
        toggleStudentDetail,
        studentDetailIndex,
        updateTasksForCourse,
        deleteConferenceForStudent,
        openStudentConferenceModal,
        staffId,
    } = useDataContext();

    const { name, trafficLight, bank, courses } = student;
    const [selectedCourseIndex, setSelectedCourseIndex] = useState(0);
    const [tasksLoading, setTaskLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    let selectedCourse = courses && courses[selectedCourseIndex];
    const coursesWithPlannedTask = courses.filter(course => course.plannedTask);
    const hasMultiplePlannedTasks = coursesWithPlannedTask && coursesWithPlannedTask.length > 1;
    const allPlannedTasks = coursesWithPlannedTask
        .map(course => {
            return course.tasks.find(task => task.id === course.plannedTask);
        })
        .filter(task => task);
    const allCoursesOptionIndex = -1;
    const allCoursesSelected = selectedCourseIndex === allCoursesOptionIndex;

    useEffect(() => {
        setSelectedCourseIndex(hasMultiplePlannedTasks ? -1 : 0);
    }, [student.name]);

    const onToggleDetail = evt => {
        if (allCoursesSelected) return;
        toggleDetail(evt);
        fetchTasks(selectedCourse);
    };

    const handleSelectCourse = selectedIndex => {
        if (selectedIndex === allCoursesOptionIndex) {
            selectedCourse = { id: -1, name: 'All Courses', tasks: allPlannedTasks };
            return;
        }
        selectedCourse = courses && courses[selectedIndex];
        fetchTasks(selectedCourse);

        if (studentDetailIndex !== student.id) {
            toggleDetail(student.id);
        }
    };

    const toggleDetail = evt => {
        const isTaskClick = taskRef?.current?.contains(evt.target);
        if (!isTaskClick) {
            toggleStudentDetail(student.id);
        }
    };

    const onDeleteConference = (conference, evt) => {
        evt.stopPropagation();

        setIsLoading(true);
        conferenceService
            .deleteConference(conference.id)
            .then(() => {
                deleteConferenceForStudent(student.id, conference.id);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onEditConference = (conference, evt) => {
        evt.stopPropagation();
        openStudentConferenceModal(student, conference.id);
    };

    const fetchTasks = course => {
        if (tasksLoading) return;
        if (course.tasks && course.tasks.length > 1) return;
        if (
            course.tasks.length === 1 &&
            (!course.plannedTask || course.tasks[0].id !== course.plannedTask)
        )
            return;

        setTaskLoading(true);
        taskService
            .fetchTasks(staffId, student.id, course.id)
            .then(response => {
                updateTasksForCourse(student, course, response.data);
                setTaskLoading(false);
            })
            .catch(() => {
                setTaskLoading(false);
            });
    };

    const plannedTask =
        selectedCourse && selectedCourse.plannedTask
            ? selectedCourse.tasks.find(task => task.id === selectedCourse.plannedTask)
            : null;

    const studentProfileLink = studentService.getStudentProfileUrl(student.id);

    return (
        <div className="student-item__wrapper">
            {isLoading && (
                <div className="student-item__loading">
                    <BeatLoader loading={isLoading} size={15} color={'#0055aa'} />
                </div>
            )}
            <div className="student-item">
                <div className="student-item__box" onClick={onToggleDetail}>
                    <div className="student-item__student">
                        <div>
                            <div className="student-item__name">
                                <div
                                    className={`traffic-light traffic-light--${trafficLight?.toLowerCase()}`}
                                >
                                    {bank}
                                </div>
                                <span>{name}</span>
                                <a
                                    href={studentProfileLink}
                                    onClick={evt => evt.stopPropagation()}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FontAwesomeIcon
                                        icon={faLink}
                                        color="#555"
                                        size="1x"
                                        className="student-profile"
                                    />
                                </a>
                            </div>
                        </div>
                        {!allCoursesSelected && (
                            <button
                                style={{
                                    background: 'transparent',
                                    transform:
                                        studentDetailIndex === student.id
                                            ? 'rotate(0deg)'
                                            : 'rotate(-180deg)',
                                }}
                            >
                                <FontAwesomeIcon icon={faCaretDown} color="#555" size="2x" />
                            </button>
                        )}
                    </div>
                    <ul className="student-item__courses">
                        {hasMultiplePlannedTasks && (
                            <li key="All Courses">
                                <button
                                    className={classnames('student-item__course', {
                                        'student-item__course--selected':
                                            allCoursesOptionIndex === selectedCourseIndex,
                                    })}
                                    onClick={evt => {
                                        evt.stopPropagation();
                                        setSelectedCourseIndex(allCoursesOptionIndex);
                                        handleSelectCourse(allCoursesOptionIndex);
                                    }}
                                >
                                    <span>All Courses</span>
                                </button>
                            </li>
                        )}
                        {courses &&
                            courses.map((course, index) => (
                                <li key={course.name}>
                                    <button
                                        className={classnames('student-item__course', {
                                            'student-item__course--selected':
                                                index === selectedCourseIndex,
                                            'student-item__course--focus-credit':
                                                course.isFocusCredit,
                                            'student-item__course--focus': course.focusCredit,
                                        })}
                                        onClick={evt => {
                                            evt.stopPropagation();
                                            setSelectedCourseIndex(index);
                                            handleSelectCourse(index);
                                        }}
                                    >
                                        {course.focusCredit && (
                                            <span className="student-item__focus-credit">
                                                <FontAwesomeIcon
                                                    icon={faSearch}
                                                    color={
                                                        index === selectedCourseIndex
                                                            ? '#ff6400'
                                                            : '#cccccc'
                                                    }
                                                    size="1x"
                                                />
                                            </span>
                                        )}
                                        <span>{course.name}</span>
                                    </button>
                                </li>
                            ))}
                    </ul>
                    <div className="student-item__plan" ref={taskRef}>
                        {student.conferences &&
                            student.conferences.map(conference => (
                                <div className="student-item__plan-conference" key={conference.id}>
                                    <div className="student-item__plan-conference-detail">
                                        <div className="student-item__plan-conference-title">
                                            {' '}
                                            <FontAwesomeIcon
                                                icon={faCalendarAlt}
                                                size="1x"
                                                color={'#999'}
                                            />
                                            {`  Conference with ${student.name}`}
                                        </div>
                                        <div className="student-item__plan-conference-date">
                                            Date: {new Date(conference.date).toLocaleString()}
                                        </div>
                                        <div className="student-item__plan-conference-description">
                                            {conference.notes}
                                        </div>
                                    </div>
                                    <div className="student-item__plan-conference-actions">
                                        <FontAwesomeIcon
                                            className="student-item__plan-conference-actions-edit"
                                            icon={faEdit}
                                            size="lg"
                                            color={'#999'}
                                            onClick={onEditConference.bind(this, conference)}
                                        />
                                        <FontAwesomeIcon
                                            className="student-item__plan-conference-actions-delete"
                                            icon={faTrash}
                                            size="lg"
                                            color={'#999'}
                                            onClick={onDeleteConference.bind(this, conference)}
                                        />
                                    </div>
                                </div>
                            ))}
                        {allCoursesSelected &&
                            allPlannedTasks.map(task => (
                                <div className="student-item__plan-task" key={task.id}>
                                    <Task
                                        course={selectedCourse}
                                        student={student}
                                        task={task}
                                        isPlanned={true}
                                        isStatic
                                        hideBorder
                                    />
                                </div>
                            ))}
                        {!allCoursesSelected && plannedTask && (
                            <div className="student-item__plan-task">
                                <Task
                                    course={selectedCourse}
                                    student={student}
                                    task={plannedTask}
                                    isPlanned={true}
                                    isStatic
                                    hideBorder
                                />
                            </div>
                        )}
                        {!allCoursesSelected && !plannedTask && !student.conference && (
                            <div className="student-item__plan-task">
                                <div className="student-item__unplanned">
                                    Identify Task / Student Needs
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <StudentDetail
                student={student}
                selectedCourse={selectedCourse}
                plannedTask={plannedTask}
                show={!allCoursesSelected && studentDetailIndex === student.id}
                tasksLoading={tasksLoading}
            />
        </div>
    );
};

export default StudentItem;
