import React, { useState } from 'react';
import { useGroupContext } from '../components/GroupContext';
import TypeAhead from '../components/TypeAhead';
import MatchingStudentsList from './components/MatchingStudentsList';

import './create-group.scss';

const CreateGroup = () => {
    const [groupType, setGroupType] = useState('students');

    const {
        students,
        selectedStudent,
        setSelectedStudent,
        selectedTask,
        setSelectedTask,
        groupName,
        groupDate,
        setGroupDate,
        studentsInGroup,
        setStudentsInGroup,
        availableTasks,
        availableStudents,
        notes,
        setNotes,
        onAddGroup,
        matchWithinCourse,
        setMatchWithinCourse,
        matchByCompetency,
        setMatchByCompetency,
    } = useGroupContext();

    const onChangeDate = evt => {
        const {
            target: { value },
        } = evt;
        setGroupDate(value);
    };

    const onChangeStudentsInGroup = (student, isSelected) => {
        let nextStudents = [];
        if (!isSelected) {
            nextStudents = studentsInGroup.filter(s => s.id !== student.id);
        } else {
            nextStudents = [...new Set(studentsInGroup.concat([student]))];
        }
        setStudentsInGroup(nextStudents);
    };

    const onChangeNotes = evt => {
        const {
            target: { value },
        } = evt;
        setNotes(value);
    };

    const onChangeMatchWithinCourse = evt => {
        const {
            target: { checked },
        } = evt;
        setMatchWithinCourse(checked);
    };

    const onChangeMatchByCompetency = evt => {
        const {
            target: { checked },
        } = evt;
        setMatchByCompetency(checked);
    };

    const onChangeGroupType = evt => {
        const {
            target: { value },
        } = evt;
        setGroupType(value);
    };
    return (
        <div>
            <div>
                <h2>{groupName}</h2>
                <div>
                    <select
                        className="select"
                        style={{ width: 200 }}
                        onChange={onChangeGroupType}
                        value={groupType}
                    >
                        <option value="students">Group by Students</option>
                        <option value="task">Group by Task</option>
                    </select>
                </div>
            </div>

            {groupType === 'students' && (
                <div className="create-group">
                    <div>
                        <label htmlFor="">Select Students For Group</label>
                        <MatchingStudentsList
                            matches={students.map(student => ({ student, task: {} }))}
                            selected={studentsInGroup}
                            onChange={onChangeStudentsInGroup}
                        />
                    </div>
                </div>
            )}
            {groupType === 'task' && (
                <div className="create-group">
                    <div>
                        <label htmlFor="">Select Anchor Student</label>
                        <TypeAhead
                            items={students}
                            selectedValue={selectedStudent}
                            prop="name"
                            alwaysOpen
                            placeholder="Filter by student"
                            onChange={setSelectedStudent}
                        ></TypeAhead>
                    </div>

                    {selectedStudent && (
                        <div>
                            <label htmlFor="">Select Task</label>
                            <TypeAhead
                                items={availableTasks}
                                prop="name"
                                alwaysOpen
                                placeholder="Filter by task"
                                selectedValue={selectedTask}
                                renderItem={item => {
                                    return (
                                        <div className="group-task">
                                            {item.name}
                                            <ul>
                                                {item.competencies &&
                                                    item.competencies.map((c, index) => (
                                                        <li key={index}>{c}</li>
                                                    ))}
                                            </ul>
                                        </div>
                                    );
                                }}
                                onChange={setSelectedTask}
                            ></TypeAhead>
                        </div>
                    )}

                    {selectedTask && (
                        <div>
                            <label htmlFor="">Select Students For Group</label>
                            <div style={{ marginTop: 10 }}>
                                <label htmlFor="match-within-course">
                                    <input
                                        id="match-within-course"
                                        type="checkbox"
                                        checked={matchWithinCourse}
                                        onChange={onChangeMatchWithinCourse}
                                    />
                                    &nbsp; Find match within course
                                </label>
                            </div>
                            <div>
                                <label htmlFor="match-by-competency">
                                    <input
                                        id="match-by-competency"
                                        type="checkbox"
                                        checked={matchByCompetency}
                                        onChange={onChangeMatchByCompetency}
                                    />
                                    &nbsp; Match by competency
                                </label>
                            </div>
                            <MatchingStudentsList
                                matches={availableStudents}
                                selected={studentsInGroup}
                                onChange={onChangeStudentsInGroup}
                            />
                        </div>
                    )}
                </div>
            )}

            <div className="create-group-notes__wrapper">
                <div className="create-group-notes">
                    <textarea
                        name=""
                        value={notes ?? ''}
                        onChange={onChangeNotes}
                        placeholder="Enter notes for group"
                    ></textarea>
                </div>
                <div className="create-group-buttons">
                    <div style={{ maxWidth: 200, marginBottom: 10 }}>
                        <label htmlFor="group-date">Enter date for group</label>
                        <input
                            id="group-date"
                            value={groupDate}
                            onChange={onChangeDate}
                            className="input"
                            type="date"
                        />
                    </div>
                    <div>
                        <label htmlFor="needs-planning-filter" style={{ marginBottom: 20 }}>
                            <input
                                id="needs-planning-filter"
                                type="checkbox"
                                name="needs-planning"
                            />
                            Recurring group
                        </label>
                    </div>
                    <button
                        className="button-white"
                        onClick={onAddGroup}
                        disabled={!studentsInGroup || studentsInGroup.length < 2}
                    >
                        Add Group
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateGroup;
