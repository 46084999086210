import React, { useEffect, useState } from 'react';
import Modal from '../../../components/Modal';
import { format, parse, parseISO } from 'date-fns';

import GoogleCalendarLink from '../../../components/GoogleCalendarLink';
import { useDataContext } from '../../../components/DataContext';
import './student-conference-modal.scss';
import { conferenceService } from '../../../services';

const StudentConferenceModal = () => {
    const [notes, setNotes] = useState('');
    const [date, setDate] = useState('');
    const [loading, setLoading] = useState(false);
    const {
        staffId,
        studentConferenceModalData,
        setConferenceForStudent,
        isStudentConferenceModalShow,
        closeStudentConferenceModal,
    } = useDataContext();

    const { student, conference } = studentConferenceModalData || {};

    useEffect(() => {
        if (conference) {
            const formattedDate = format(
                parse(conference.date, 'MM/dd/yyyy', new Date()),
                'yyyy-MM-dd',
            );
            setNotes(conference.notes);
            setDate(formattedDate);
        } else {
            setNotes('');
            setDate('');
        }
    }, [isStudentConferenceModalShow]);

    const onHide = () => {
        closeStudentConferenceModal();
    };

    const onChange = evt => {
        evt.stopPropagation();
        const {
            target: { value },
        } = evt;
        setNotes(value);
    };

    const onSave = () => {
        setLoading(true);
        const formattedDate = format(parseISO(date), 'MM/dd/yyyy');
        const conferenceData = {
            date: formattedDate,
            notes,
        };
        if (conference?.id) {
            conferenceData['id'] = conference.id;
            editConference(conferenceData);
        } else saveConference(conferenceData);
    };

    const saveConference = newConference => {
        conferenceService
            .saveConference(staffId, student.id, date, notes)
            .then(result => {
                newConference['id'] = result.data;
                setConferenceForStudent(student.id, newConference);
            })
            .finally(() => {
                setLoading(false);
                onHide();
            });
    };

    const editConference = editedConference => {
        editedConference['id'] = conference.id;
        conferenceService
            .editConference(editedConference.id, staffId, student.id, date, notes)
            .then(() => {
                setConferenceForStudent(student.id, editedConference);
            })
            .finally(() => {
                setLoading(false);
                onHide();
            });
    };

    const onChangeDate = evt => {
        const {
            target: { value },
        } = evt;
        setDate(value);
    };

    if (!student) return <></>;

    const title = `Conference for ${student.name}`;

    if (isStudentConferenceModalShow) {
        return (
            <Modal onClose={onHide} loading={loading}>
                <div className="student-conference">
                    <h2>Create Conference for {student.name}</h2>
                    <div style={{ marginBottom: 10 }}>
                        <label htmlFor="group-date">{`Enter date for your conference with ${student.name}`}</label>
                        <input
                            id="conference-date"
                            value={date}
                            onChange={onChangeDate}
                            className="input"
                            type="date"
                        />
                    </div>
                    <div>
                        <textarea
                            value={notes ?? ''}
                            onChange={onChange}
                            placeholder="Enter description here"
                        ></textarea>
                    </div>
                    <div className="student-conference__button">
                        <GoogleCalendarLink
                            title={title}
                            description={notes}
                            label="Save"
                            color="#272cba"
                            onClick={onSave}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
    return <></>;
};

export default StudentConferenceModal;
