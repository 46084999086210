import axios from 'axios';
import config from '../config';

export const fetchPlanData = (staffid, cancelSource) => {
    return axios.get(`${config.BASE_URL}${config.FETCH_PLAN_ENDPOINT}`, {
        cancelToken: cancelSource.token,
    });
};

export const fetchPlanHistory = staffid => {
    return axios.get(`${config.BASE_URL}${config.FETCH_PLAN_HISTORY_ENDPOINT}`);
};

export const renamePlan = (planId, planName) => {
    return axios.get(
        `${config.BASE_URL}${config.RENAME_FACILITATION_PLAN_ENDPOINT}?planId=${planId}&name=${planName}`,
    );
};

export const getDownloadCsvUrl = planId => {
    return `${config.BASE_URL}${config.DOWNLOAD_CSV_ENDPOINT}?id=${planId}`;
};

export const savePlan = staffid => {
    return axios.get(`${config.BASE_URL}${config.SAVE_PLAN_ENDPOINT}`);
};
